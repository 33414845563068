import { types, applySnapshot } from 'mobx-state-tree';

const InstantReward = types
    .model('InstantReward', {
        id: types.identifierNumber,
        name: types.optional(types.string, ''),
        image_url: types.optional(types.string, ''),
        description: types.string,
        redeem_description: types.optional(types.string, ''),
        popup_title: types.optional(types.string, ''),
        sponsor_link: types.maybeNull(types.string, ''),
        popup_text: types.optional(types.string, ''),
        quantity: types.optional(types.number, 0),
        acquired: types.maybeNull(types.number),
        how_to_claim_reward: types.optional(types.string, ''),
        expire_at: types.maybeNull(types.string),
        type: types.optional(types.string, 'avatar'),
        recipients: types.maybeNull(types.array(types.number)),
        price_in_cents: types.maybeNull(types.number),
        used: types.maybeNull(types.number),
        use_unused: types.optional(types.boolean, false),
        order_id: types.maybeNull(types.string),
        voucher_type: types.maybeNull(types.string),
    })
    .actions((self) => ({
        editInstantReward(edited) {
            applySnapshot(self, { ...self, ...edited });
        },
    }));

export default InstantReward;
