import api from './apiLibrary';

const API = {
    getMatches(params) {
        return api.get(`/api/admin/game`, params);
    },
    updateMatches(season) {
        return api.post(`/api/admin/league-statistics/update-matches-new/${season}`);
    },
    getGameOwnerGames(params) {
        return api.get('/api/admin/game?onlyOwnerGames=true', params);
    },
    getHomeGames(params) {
        return api.get('/api/admin/game?onlyHomeGames=true', params);
    },
    getMatch(id, params) {
        return api.get(`/api/admin/game/${id}`, params);
    },
    getGraphStats() {
        return api.get(`/api/admin/game/stats`);
    },
    createMatch(data) {
        return api.post('/api/admin/game', data);
    },
    editMatch(id, data) {
        return api.put(`/api/admin/game/${id}`, data);
    },
    deleteMatch(id) {
        return api.delete(`/api/admin/game/${id}`);
    },
    createFanGames(id) {
        return api.post(`/api/admin/fan-game/${id}/add-fan-games`);
    },
    getMatchStatistics(params) {
        return api.get('/api/admin/game/statistics', params);
    },
};

export default API;
