import Input from '../../components/Input';
import InputFile from '../../components/InputFile';
import DatePicker from '../../components/DatePicker';
import mandatoryFields from '../../configs/reward';
import Textarea from '../../components/Textarea';
import translateLabel from '../../helpers/translateLabel';
import voucherTypes from '../../constants/voucherTypes';
import Select from '../../components/Select';
import Checkbox from '../../components/Checkbox';

import rewardTypes from '../../constants/rewardTypes';

import translations from '../../translations/de';
import ImportVoucherCodes from '../../components/ImportVoucherCodes/ImportVoucherCodes';
import InputEmpty from '../../components/InputEmpty';
import '../../components/Item/Item.scss';

export default function getInstantRewardFormInput({
    value,
    name,
    register,
    control,
    disabled,
    error,
    index,
    resetImageValue,
    resetVoucherValue,
    type = 'consumable',
    voucherType,
    orderID,
    setOrderID,
    searchOrdersHandler,
    orders,
    handleOrder,
    disableVoucherFields,
    handleVoucherTypeChange,
    comesFromEdit = false,
    price_in_cents,
    showSingleVoucherCodeField = false,
    shippingOnCreate = false,
    receiverOptions = [],
}) {
    if (name === 'expire_at') {
        return (
            <DatePicker
                key={index}
                label={translateLabel(name)}
                selectText="Select a date..."
                name={name}
                defaultValue={value}
                control={control}
                withTime={true}
                minDate={new Date()}
                disabled={disabled}
                error={error}
                isRequired={mandatoryFields.find((field) => (field === name ? true : false))}
            />
        );
    } else if (name === 'order_id') {
        const isVoucherTypeValid = voucherType !== 'internal' && voucherType;
        const isFixedTypeValid = voucherType && voucherType !== 'internal';

        if (isVoucherTypeValid || isFixedTypeValid) {
            return (
                type === 'consumable' && (
                    <Select
                        key={`index_${voucherType}-${orderID}`}
                        onInputChange={searchOrdersHandler}
                        name={name}
                        label={translateLabel(translations.order_id)}
                        options={orders.map((order) => ({
                            value: order,
                            label: `${order}`,
                        }))}
                        defaultValue={value}
                        onChangeCallback={handleOrder}
                        placeholder={translations.search_orders}
                        control={control}
                        error={error}
                        disabled={disabled || orderID !== ''}
                        noOptionsMsg={translations.no_orders_found}
                        isClearable
                        isRequired={mandatoryFields.includes(name)}
                    />
                )
            );
        } else {
            return <InputEmpty key={index} />;
        }
    } else if (name === 'image_url') {
        return (
            <InputFile
                key={index}
                label={translateLabel(name)}
                name={name}
                {...register(name, {
                    required: true,
                })}
                error={error}
                control={control}
                disabled={disabled}
                resetValue={(val) => resetImageValue(name, val)}
                pathToCloudFolder="uploads/instant-rewards"
            />
        );
    } else if (name === 'description' || name === 'popup_text') {
        return (
            <Textarea
                key={index}
                label={translateLabel(name === 'description' ? 'description_voucher' : name)}
                name={name}
                type="text"
                {...register(name, {
                    required: true,
                })}
                error={error}
                disabled={disabled}
            />
        );
    } else if (name === 'sponsor_link') {
        return voucherType === 'external' && type === 'consumable' ? (
            <Input
                key={index}
                label={translateLabel(name)}
                name={name}
                disabled={disabled}
                defaultValue={value}
                {...register(name, {
                    required: mandatoryFields.find((field) => (field === name ? true : false)),
                    validate: {
                        checkUrl: (value) => {
                            if (!value) {
                                return true;
                            }
                            try {
                                new URL(value);
                                return true;
                            } catch (error) {
                                return 'Invalid URL';
                            }
                        },
                    },
                })}
                error={error}
            />
        ) : (
            <>
                <InputEmpty key={index} />
            </>
        );
    } else if (name === 'price_in_cents') {
        return voucherType === 'eguma' ? (
            <Input
                key={`${index}`}
                label={translateLabel(name)}
                name={name}
                type={typeof value}
                control={control}
                readOnly={disableVoucherFields}
                disabled={disabled}
                {...register(name, {
                    required: disableVoucherFields
                        ? false
                        : mandatoryFields.find((field) => (field === name ? true : false)),
                    valueAsNumber: true,
                    min: disableVoucherFields
                        ? 0
                        : { value: 1, message: translations.positive_number },
                })}
                error={error}
            />
        ) : (
            <InputEmpty key={index} />
        );
    } else if (name === 'type') {
        return (
            <Select
                key={index}
                isSearchable={false}
                label={translateLabel(name)}
                name={name}
                disabled={comesFromEdit ?? (disabled || disableVoucherFields)}
                options={rewardTypes}
                control={control}
                error={error}
                //onChangeCallback={(e) => handleTypeChange(e)}
                isRequired={true}
            />
        );
    } else if (name === 'receiver') {
        return (
            <Select
                key={index}
                label={translateLabel(name)}
                options={receiverOptions.filter(
                    (option) =>
                        option.value === 'non_seasonal_ticket_holders' ||
                        option.value === 'seasonal_ticket_holders' ||
                        option.value === 'all_users',
                )}
                isSearchable={false}
                isClearable={true}
                name={name}
                control={control}
                error={error}
                disabled={comesFromEdit ?? disabled}
                isRequired={false}
            />
        );
    } else if (name === 'voucher_type') {
        return (
            type === 'consumable' && (
                <Select
                    key={`my_unique_select_key__${JSON.stringify(value)}`}
                    label={translateLabel(name)}
                    options={voucherTypes}
                    isSearchable={false}
                    isClearable={true}
                    name={name}
                    control={control}
                    error={error}
                    disabled={comesFromEdit ?? disabled}
                    isRequired={false}
                    onChangeCallback={(e) => handleVoucherTypeChange(e)}
                />
            )
        );
    } else if (name === 'acquired' || name === 'used') {
        return (
            disabled && (
                <Input
                    key={index}
                    label={translateLabel(name)}
                    name={name}
                    disabled={disabled}
                    type={typeof value}
                    defaultValue={value}
                    {...register(name, {
                        required: mandatoryFields.find((field) => (field === name ? true : false)),
                        valueAsNumber: typeof value === 'number' ? true : false,
                        min:
                            typeof value === 'number'
                                ? { value: 1, message: translations.positive_number }
                                : undefined,
                    })}
                    error={error}
                />
            )
        );
    } else if (name === 'push_check') {
        return (
            <div style={{ width: '49%' }}>
                <p style={{ fontWeight: 'bold', margin: '0' }}>Push:</p>
                <Checkbox
                    disabled={disabled}
                    key={index}
                    text={translateLabel(name)}
                    name={name}
                    {...register(name, { required: false })}
                />
            </div>
        );
    } else if (name === 'single_voucher_check') {
        return voucherType !== '' &&
            voucherType !== 'internal' &&
            voucherType !== null &&
            type === 'consumable' ? (
            <div className="shipping-wrapper">
                <p style={{ fontWeight: 'bold', margin: '0' }}>Codes:</p>
                <Checkbox
                    disabled={disabled}
                    key={index}
                    text={translateLabel(name)}
                    name={name}
                    {...register(name, { required: false })}
                />
            </div>
        ) : (
            <InputEmpty key={index} />
        );
    } else if (name === 'shared_code') {
        return showSingleVoucherCodeField &&
            voucherType !== 'internal' &&
            voucherType !== '' &&
            voucherType !== null ? (
            <div style={{ width: '49%' }}>
                <div style={{ height: '10px' }} />
                <Input
                    style={{ width: '100%' }}
                    key={index}
                    label={translateLabel(name)}
                    name={name}
                    disabled={disabled}
                    type={typeof value}
                    defaultValue={value}
                    {...register(name, {
                        required: mandatoryFields.find((field) => (field === name ? true : false)),
                    })}
                    error={error}
                />
            </div>
        ) : (
            <InputEmpty key={index} />
        );
    } else if (name === 'shipping_check') {
        return (
            <div className="shipping-wrapper">
                <p style={{ margin: '0' }}>Versand:</p>
                <Checkbox
                    disabled={disabled}
                    key={index}
                    text={translateLabel(name)}
                    name={name}
                    {...register(name, { required: false })}
                />
            </div>
        );
    } else if (name === 'schedule_date') {
        return !shippingOnCreate ? (
            <div className="shipping-wrapper">
                <div style={{ height: '10px' }} />
                <DatePicker
                    key={index}
                    label={translateLabel(name)}
                    selectText="Select a date..."
                    name={name}
                    defaultValue={value}
                    control={control}
                    withTime={true}
                    disabled={disabled}
                    error={error}
                    minDate={new Date()}
                    isRequired={!shippingOnCreate ? true : false}
                />
            </div>
        ) : (
            <InputEmpty key={index} />
        );
    } else if (name === 'number_of_recipients') {
        return (
            <Input
                value={value}
                key={index}
                label={translateLabel(name)}
                name={name}
                disabled={true}
                type={typeof value}
                // defaultValue={value}
                // {...register(name, {
                //     required: mandatoryFields.find((field) => (field === name ? true : false)),
                // })}
                error={error}
            />
        );
    } else if (name === 'dummy') {
        return <InputEmpty key={index} />;
    } else if (name === 'importCodesField') {
        const isFixedTypeValid =
            voucherType && voucherType !== 'internal' && !showSingleVoucherCodeField;
        return isFixedTypeValid && type === 'consumable' ? (
            <ImportVoucherCodes
                value={value}
                key={`${index}-${orderID}`}
                label={translateLabel(name)}
                name={name}
                {...register(name, {
                    required: false,
                })}
                error={error}
                control={control}
                disabled={disabled}
                orderID={orderID}
                setOrderID={setOrderID}
                voucherType={voucherType}
                resetValue={(val) => resetVoucherValue(name, val)}
                currentPriceInCents={price_in_cents}
            />
        ) : (
            <InputEmpty key={index} />
        );
    } else if (typeof value === 'boolean') {
        return (
            <Checkbox
                disabled={disabled}
                key={index}
                text={translateLabel(name)}
                name={name}
                {...register(name, { required: false })}
            />
        );
    }  else if (typeof value === 'string' || typeof value === 'number') {
        return (
            <Input
                key={index}
                label={translateLabel(name)}
                name={name}
                type={typeof value}
                control={control}
                defaultValue={value}
                disabled={disabled}
                {...register(name, {
                    required: mandatoryFields.find((field) => (field === name ? true : false)),
                    valueAsNumber: typeof value === 'number' ? true : false,
                })}
                error={error}
            />
        );
    } else if (name === 'use_unused') {
        return (
            !comesFromEdit &&
            voucherType !== null && (
                <Checkbox
                    disabled={disabled || disableVoucherFields}
                    key={index}
                    text={translateLabel(name)}
                    name={name}
                    {...register(name, { required: false })}
                />
            )
        );
    } else {
        return (
            <Input
                key={index}
                control={control}
                label={translateLabel(name)}
                disabled={disabled}
                name={name}
                {...register(name, {
                    required: mandatoryFields.find((field) => (field === name ? true : false)),
                })}
                error={error}
            />
        );
    }
}
