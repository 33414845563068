import React from 'react';
import Paths from '../../constants/paths';
import MatchOverview from './MatchOverview';
import Page from '../../components/Page';
import { Route } from 'react-router-dom/cjs/react-router-dom.min';
import ErrorMessages from '../../components/ErrorMessages.js';
import { observer } from 'mobx-react-lite';

const MatchOverviewTab = ({ overviewStore, freundeStore }) => {
    return (
        <>
            <Page loading={!overviewStore.dataLoaded}>
                <div style={{ marginLeft: '2.5%' }}>
                    <ErrorMessages messages={overviewStore.error} />
                </div>
                <Route exact path={Paths.MATCH_OVERVIEW}>
                    <MatchOverview store={overviewStore} freundeStore={freundeStore} />
                </Route>
            </Page>
        </>
    );
};

export default observer(MatchOverviewTab);
