import { useEffect, useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import Paths from './constants/paths';
import Header from './components/Header';
import Drawer from './components/Drawer';
import DrawerStore from './models/adminViewStore/Drawer';
import Login from './pages/Login';
import { adminAccessPaths } from './helpers/adminAccess';
import { AuthorizedRoutesConfig } from './routes';
import Loader from './components/Loader';
import ForgotPassword from './pages/ResetPasswordFlow';

const App = ({ adminStore }) => {
    const drawer = DrawerStore.create();
    const [view, setView] = useState(false);

    useEffect(() => {
        adminStore
            .getAdminUser()
            .then(() => setView(true))
            .catch(() => setView(true));
    }, [adminStore]);

    return (
        <main>
            {view ? (
                adminStore.isAdminLoggedIn() ? (
                    <>
                        <Header store={drawer} adminStore={adminStore} />
                        <Drawer store={drawer} adminStore={adminStore} />
                        <Switch>
                            {AuthorizedRoutesConfig.map(({ path, isExact, component: C, props }) =>
                                adminAccessPaths(adminStore.roles).find((p) => p === path) ? (
                                    <Route
                                        component={() => <C {...props} adminStore={adminStore} />}
                                        exact={isExact}
                                        key={path}
                                        path={path}
                                    />
                                ) : null,
                            )}
                            <Route path="/">
                                <Redirect to={Paths.HOME} />
                            </Route>
                        </Switch>
                    </>
                ) : (
                    <Switch>
                        <Route exact path={Paths.LOGIN}>
                            <Login adminStore={adminStore} />
                        </Route>
                        <Route exact path={Paths.FORGOT_PASSWORD}>
                            <ForgotPassword />
                        </Route>
                        <Route path="/">
                            <Redirect to={Paths.LOGIN} />
                        </Route>
                    </Switch>
                )
            ) : (
                <Loader size="big" theme="dark" />
            )}
        </main>
    );
};

export default observer(App);
