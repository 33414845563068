const translations = {
    app_name: 'SCB Backoffice',
    mobile_version: 'Mobile Version',
    username: 'Benutzername',
    password: 'Passwort',
    forgot_password: 'Passwort vergessen',
    new_password: 'Neues Passwort',
    repeat_password: 'Passwort wiederholen',
    save_changes: 'Änderungen speichern',
    send_code: 'Send code',
    required: 'Erforderlich',
    login: 'Login',
    logout: 'Logout',
    edit: 'editieren',
    save: 'Speichern',
    cancel: 'Abbrechen',
    delete: 'löschen',
    show: 'zeigen',
    close: 'Schließen',
    search_title: 'Suchen',
    success: 'Erfolg',
    forgot_password_success_message:
        'Der Link zum Zurücksetzen wurde an die angegebene E-Mail-Adresse gesendet.',
    reset_password_success_message: 'Passwort wurde erfolgreich geändert!',
    passwords_not_matching: 'Passwörter stimmen nicht überein!',
    search: 'Suchen...',
    select: 'Wählen...',
    new: 'Neu',
    create: 'erfassen',
    general: 'Allgemein',
    buyer: 'Käufer',
    owner: 'Eigentümer',
    action: 'Aktion',
    actions: 'Aktionen',
    send: 'Senden',
    send_to: 'Senden an',
    admin_users: 'Admin-Users',
    admin_user: 'Admin-User',
    user: 'Benutzer',
    users: 'Users',
    all_user: 'Alle Nutzer',
    all_sponsors: 'Saisonabo Benutzer',
    type_to_search_users: 'Hinzufügen, um mit Business-Konto zu verknüpfen',
    no_users_found: 'keine Benutzer gefunden',
    no_orders_found: 'No orders found',
    no_profiles_found: 'No Results...',
    select_date: 'Datum auswählen...',
    type_to_search_seats: 'eingeben, um nach einem Sitzplatz zu suchen (z. B. sec-1 oder 100).',
    no_seats_found: 'keine Sitze gefunden',
    select_season: 'Spielzeit auswählen...',
    no_seasons_found: 'keine Spielzeit gefunden',
    positive_number: 'Must be positive number!',
    zero_or_positive_number: 'Must be equal to 0 or higher!',
    logo: 'Logo',
    current: 'Aktuell?',
    male: 'Herr',
    female: 'Frau',
    other: 'Andere',
    details: 'Details',
    avatarSizeLabel: 'Recommended 100x100 pixels or lower...',
    imageSizeLabel: 'Recommended 360x800 pixels or higher...',
    imageLabelPng: 'Accepts PNG files only',
    export: 'export.csv',
    export_users: 'Export Users',
    export_fan_games: 'Export Fan Games',
    export_vouchers: 'Export Gutscheine',
    transactions: 'Transaktionen',
    balance_before: 'Balance Before',
    balance_after: 'Balance After',
    balance_history: 'Balance History',
    //  drawer
    vouchers_drawer: 'Gutscheine',
    vouchers_drawer_db: 'Gutscheine DB',
    //--------------------Tabs------------------
    tab_name: 'Tab Name',
    home: 'Home',
    seat: 'Sitz',
    seats: 'Sitze',
    scanner: 'Scanner',
    news: 'News',
    pass: 'Abo',
    passes: 'Abos',
    passes_column: 'Zutritte',
    passesFriendship: 'Freunde Tickets',
    access: 'eTicket',
    accesses: 'eTickets',
    notification: 'Benachrichtigung',
    notifications: 'Benachrichtigungen',
    ticket_export: 'Export Ticketliste',
    teams: 'Teams',
    team: 'Team',
    player: 'Spieler',
    staff: 'Staff',
    match: 'Spiel',
    matches: 'Spiele',
    sector: 'Sektor',
    sectors: 'Sektoren',
    entrances: 'Eingänge',
    seat_groups: 'Blöcke',
    seat_group: 'Block',
    seat_assignment: 'Sitzzuordung',
    seat_assignments: 'Sitzzuordungen',
    season: 'Spielzeit',
    seasonal_pass: 'Saisonkarten',
    single_pass: 'Einzeltickets',
    freunde_pass: 'Fründe Tickets',
    total: 'Total',
    more: 'More',
    seasons: 'Spielzeiten',
    shop_item: 'Shop Item',
    shop_items: 'Shop Items',
    feeds: 'Feeds',
    app_screens: 'App Screens',
    app_screen: 'App Screen',
    banners: 'Banners',
    banner: 'Banner',
    business_profiles: 'Business Profiles',
    business_profile: 'Business Profile',
    instant_rewards: 'Instant Rewards',
    instant_reward: 'Instant Reward',
    search_reward: 'Type to search for reward',
    search_orders: 'Type to search orders',
    match_statistics: 'Status',
    analytics: 'Analytics',
    match_overview: 'Matches Overview',
    fan_games: 'Fan Games',
    fan_game: 'Fan Game',
    contacts: 'Kontakte',
    contact: 'Kontakt',
    feed: 'Feed',
    deleted_at: 'Deleted At',
    eguma: 'E-Guma',
    graph: 'Grafik',
    data: 'Data Table',
    visited_bracket: 'Percentages',
    passes_used: 'Number of Used Passes',
    percentages: 'Percentages',
    marketingKPI: 'Marketing KPI',
    ticketingKPI: 'Ticketing KPI',

    //---------------input labels------------------
    // user
    email: 'E-Mail',
    game_id: 'Game ID',
    first_name: 'Vorname',
    last_name: 'Nachname',
    gender: 'Anrede',
    avatar: 'Avatar',
    birthday: 'Geburtsdatum',
    phone: 'Telefon',
    country: 'Land',
    city: 'Ort',
    street: 'Strasse & Nr.',
    zip: 'PLZ',
    has_verified_email: 'Aktiv?',
    is_deleted: 'Ist gelöscht?',
    roles: 'Roles',
    fan_name: 'Fan Name',
    fan_points: 'Fan Points',
    rank: 'Rank',
    coins: 'Credits',
    fan_coins: 'Credits',
    accept_marketing: 'Marketing?',
    no_rewards_and_purchases: 'has no Rewards and Purchases yet!',
    //  staff tab
    staff_order: 'Staff Order',
    staff_order_placeholder: 'Choose order',
    no_staff_order_found: 'No staff members',
    // notification
    title: 'Titel',
    text: 'Text',
    image: 'Image',
    recipients: 'Empfänger',
    no_recipients_message:
        'Bitte wählen Sie eine der Optionen aus oder geben Sie Benutzer ein, denen Sie eine Benachrichtigung senden möchten.',
    send_to_all_message: 'Möchten Sie wirklich allen Benutzern eine Nachricht senden?',
    // pass
    assign_token: 'Token',
    type: 'Typ',
    create_anonymous_links: 'Anonymous link?',
    company: 'Firma',
    white_space_error: 'Ticked ID cannot be empty or contain white space!',
    create_all_tickets: 'Create all e-tickets',
    home_games: 'Heimspiele',
    all: 'Alle',
    // access:
    access_owner: 'Eingeladene Person',
    access_token: 'Access Token',
    scanned_at: 'Gescannt bei',
    scan: 'Scan',
    not_scanned: 'Not Scanned',
    no_access: 'No Access',
    wrong_phone_format: 'Falsches Telefon-Format',
    send_sms: 'SMS versenden',
    print_ticket: 'Ticket drucken',
    updated_notification: 'Benachrichtigung wird aktualisiert',
    can_claim_freunde: 'Can Claim Freunde',
    yes: 'Ja',
    no: 'Keine',
    // team
    logo_url: 'Logo Url',
    // player
    postion: 'Position',
    number: 'Nummer',
    shot_side: 'Shot Side',
    height: 'Height (cm)',
    weight: 'Weight (kg)',
    rooster: 'Roster',
    player_points: 'Spielerpunkte',
    // match
    home_team: 'Heimteam',
    away_team: 'Auswärtsteam',
    opponent: 'Gegner',
    location: 'Ort',
    scenario: 'Szenario',
    date: 'Datum',
    time: 'Zeit',
    score: 'Ergebnis',
    scb_home: 'SCB Home',
    home_score_error: "Home score doesn't match with its thirds!",
    guest_score_error: "Guest score doesn't match with its thirds!",
    // seat
    row: 'Reihe',
    num: 'Sitz Nummer',
    original_seat: 'Ursprünglicher Sitz',
    assigned_seat: 'Zugeordneter Sitz',
    // scanner
    device_id: 'Geräte-ID',
    app_version: 'App-Version',
    covid_token: 'COVID-API Token',
    covid_env: 'COVID-API Umgebung',
    enabled: 'aktiv?',
    //feeds
    created_at: 'Erstellt am',
    updated_at: 'Aktualisiert am',
    screen_title: 'Bildschirmtitel',
    title_label: 'Titelschild',
    url: 'Url',
    // shop item
    shop_item_name: 'Name',
    name: 'Name',
    specialName: 'Vorname / Name',
    description: 'Description',
    description_voucher: 'Beschreibung Gutschein',
    image_url: 'Bild URL',
    price: 'Price',
    stock: 'Stock',
    sold: 'Sold',
    start_stock: 'Start Stock',
    redeem_description: 'Beschreibung Einlösung',
    active: 'Active?',
    active2: 'Aktiv',
    acquirer: 'Gewinner',
    acquired: 'Acquired',
    active_status: 'Active',
    date_of_sale: 'Datum des Verkaufs',
    date_of_scan: 'Datum des Scans',
    date_of_expire: 'Datum des Ablaufs',
    //shop-item statistics tab
    active_label: 'Shop items have been bought but still not used.',
    available_label: 'Shop items are available to be bought.',
    used_label: 'Shop items have been used.',
    expired_label: 'Shop items have expired.',
    shop_item_active: 'Active',
    shop_item_available: 'Available',
    shop_item_used: 'Used',
    shop_item_expired: 'Exipred',
    // banner
    screen: 'Screen',
    position: 'Position',
    size: 'Size',
    target_url: 'Target Url',
    btn_title: 'Button title',
    banner_id: 'Banner type',
    // banner types
    small_1: 'Small 1',
    small_2: 'Small 2',
    smaill_3: 'Small 3',
    big_no_btn: 'Banner without a Button',
    big_btn: 'Banner with a Button',
    // business profile
    role: 'Position',
    phone_mobile: 'Mobil',
    search_partner: 'Type to search for a business partner',
    noPartnersOptions: 'No partners found',
    user_name_long: 'Bestehendes mySCB-Konto',
    // business partners
    partner: 'Partner',
    business_partners: 'Business Partners',
    business_partner: 'Business Partner',
    address: 'Strasse & Nummer',
    // business events
    business_events: 'Business Events',
    business_event: 'Business Event',
    start: 'Beginn',
    end: 'Ende',
    program: 'Programm',
    subtitle: 'Anrede',
    status: 'Status',
    event_passed: 'Passed',
    event_upcoming: 'Upcoming',
    passed: 'Passed Events',
    upcoming: 'Upcoming Events',
    event_ongoing: 'Ongoing',
    event_details: 'Event Details',
    invitations: 'Invitations',
    add_remove_invitations: 'Add/Remove Invitations',
    participants: 'Participants',
    attendees: 'Attendees',
    type_to_search_profiles: 'Type to search profiles',
    update_invitations: 'Update Invitations',
    people: 'People',
    select_people: 'Select People',
    selected_people: 'Selected People',
    search_name_email: 'Suchen Name, Email...',
    search_partner_name: 'Suchen Parnter Name...',
    search_by_game: 'Suche nach Spiel',
    postbox: 'Postfach',
    call: 'Einladen',
    time_to_respond: 'Anmeldeschluss',
    is_respondable: 'Anmeldungen einschalten',
    // instant rewards
    popup_text: 'Popup Text',
    popup_title: 'Popup Titel',
    expire_at: 'Gültig bis',
    rewarded: 'Rewarded',
    winners: 'Winners',
    send_random: 'Send to random ticket holders',
    shipping: 'Versand',
    shipping_check: 'Versand bei Erstellung',
    schedule_date: 'Datum Versand',
    shared_code: 'Einzelcode',
    push_check: 'Push when delivered',
    delayed_delivery_error_1: 'Scheduled date cannot surpass expiry date!',
    //recipent_options
    all_users: 'all',
    non_seasonal_ticket_holders: 'No Season Ticket',
    seasonal_ticket_holders: 'Season Ticket',
    //analytics
    show_rate: 'Show Rate',
    compare: 'Vergleiche',
    activations: 'Aktivierungen',
    tickets_created: 'Tickets erstellt',
    scanned_tickets_xaxis: 'Percentage of visited games',
    scanned_ticket_yaxis: 'Number of scanned tickets',
    scanned_tickets_1: ' tickets went to',
    scanned_tickets_2: '% of games',
    scanned_not_visited: "tickets didn't go to any game",
    tickets_not_activated: 'tickets have not been activated',
    activated_tickets_xaxis: 'Overview of dates when the tickets have been activated',
    activated_tickets_yaxis: 'Number of activated tickets',
    created_tickets_xaxis: 'Overview of dates when the tickets have been created',
    created_tickets_yaxis: 'Number of created tickets',
    // matches
    one_third_home_score: 'First Third Score - Home',
    two_thirds_home_score: 'Second Third Score - Home',
    three_thirds_home_score: 'Last Third Score - Home',
    one_third_away_score: 'First Third Score - Guest',
    two_thirds_away_score: 'Second Third Score - Guest',
    three_thirds_away_score: 'Last Third Score - Guest',
    update_games: 'Spiele aktualisieren',
    // match statistics
    pass_count: 'Tickets verfügbar',
    lap_seats: 'Schosskinder',
    created: 'Erstellt',
    opened_accesses: 'Geöffnet',
    completed: 'Bereit',
    scanned: 'Gescannt',
    unknown: 'Unbekannt ',
    //fan games
    fan_game_type: 'Fan Game Type',
    ranking: 'Ranking',
    //vouchers
    vouchers: 'Vouchers',
    voucher: 'Gutscheine',
    upload: 'Hochladen',
    new_voucher_manually: 'Manuell erstellen', // create manually
    new_voucher_from_file: 'Aus Datei erstellen', // create from file
    new_voucher: 'Gutschein',
    choose_template: 'Chose Template',
    searchShopItem: 'Type to search for shop item',
    searchRewards: 'Type to search for reward',
    noOptionShopItem: 'Shop Item not found',
    noOptionRewards: 'Reward not found',
    redeem_data: 'Redeem Data',
    price_in_cents: 'Price in Cents',
    order_id: 'Order ID',
    item_id: 'Item ID',
    voucher_code: 'Upload Codes',
    voucher_code_table: 'Codes',
    voucher_code_table_single: 'Code',
    voucher_type: 'Voucher Typ',
    consumable: 'Voucher',
    used_at: 'Used At',
    assigned: 'Is assigned',
    used: 'Used',
    expiry_date: 'Expiry Date',
    reward: 'Reward',
    rewards: 'Rewards',
    new_reward: 'New Reward',
    receiver: 'Empfänger',
    created_total: 'Total Erstellt',
    still_available: 'Noch verfügbar',
    per_game: '# pro Spiel',
    game_number: '# Spiel',
    game: 'Spiel',
    //news
    new_news: 'Create news',
    hidden: 'Hidden',
    refetch_news: 'Re-fetch News',
    publishing_date: 'Publishing date',
    //shop item
    sales: 'Käufe',
    statistics: 'Statistik',
    //lottery game
    lottery_games: 'Lottery Games',
    lottery_game: 'Lottery Game',
    lottery_game_new: 'New Lottery Game',
    lottery_ticket: 'Lottery Ticket',
    drawn: 'Draw?',
    drawn_at: 'Draw At',
    lottery_game_name: 'Lottery Game Name',
    lottery_details: 'Lottery Details',
    is_winner: 'Winner?',
    tickets: 'Tickets',
    draw_lottery: 'Draw Lottery',
    //  voucher preview
    preview_description: 'Beschribung',
    valid_until: 'Gültig bis',
    voucher_reedem: 'Gutschein Einlösen',
    copy: 'Code Kopieren',
    preview_link: 'Tagesanzeiger.ch',
    credit: 'Gutschein Einlösen',
    qr_code_desc_eguma:
        'Zeige den QR-Code vor, um dein Guthaben zu nutzen. Aktuelles Guthaben: CHF 4.11 von CHF 4.11',
    qr_code_desc_internal: 'Zeige den QR-Code vor, um den Preis zu erhalten.',
};
;

export default translations;
