import React, { useState, useEffect, memo } from 'react';

import Select from '../Select';
import Loader from '../Loader';

import { removeTwoDigits, setDefaultSeason } from '../../helpers/seasonFunctions';

import translations from '../../translations/de';
import APISeason from '../../api/seasons';

const InputSeason = ({ selectedSeason, setSelectedSeason, hideLabel = false, setAllSeasons }) => {
    const [seasons, setSeasons] = useState([]);
    const [seasonLoader, setSeasonLoader] = useState(false);

    useEffect(() => {
        const getSeasons = async () => {
            setSeasonLoader(true);
            try {
                var res = await APISeason.getSeasons();
                setSeasons(res.data);
                setAllSeasons && setAllSeasons(res.data);
                setSeasonLoader(false);
                /// set selectedSeason
                const activeSeason = res.data.find((season) => season.current === true);
                setSelectedSeason(activeSeason ? activeSeason.id : 4);
            } catch (err) {
                console.log(err);
                setSeasonLoader(false);
            }
        };
        getSeasons();
    }, [setSelectedSeason, setAllSeasons]);

    return (
        <>
            {!seasonLoader ? (
                seasons.length > 0 && (
                    <Select
                        label={!hideLabel ? translations.season : null}
                        defaultValue={setDefaultSeason(seasons, Number(selectedSeason))}
                        placeholder={translations.search}
                        options={seasons.map((season) => {
                            return {
                                value: season.id,
                                label: removeTwoDigits(season.title),
                            };
                        })}
                        onChange={(event) => setSelectedSeason(event.value)}
                    />
                )
            ) : (
                <div
                    style={{
                        width: '217px',
                        display: 'flex',
                        // marginTop: '16px',
                        // height: '38px',
                        alignItems: 'center',
                    }}
                >
                    <Loader size="small" theme="dark" />
                </div>
            )}
        </>
    );
};

export default memo(InputSeason);
