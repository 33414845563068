import React, { useState, useEffect } from 'react';
import api from '../../api/games';
import { formatDate } from '../../helpers/formatDate';
import translations from '../../translations/de';
import Select from '../Select';

const HomeGamesFilter = ({
    defaultValue = undefined,
    filterByGameDate,
    activeSeason = undefined,
}) => {
    const [games, setGames] = useState([{ value: undefined, label: translations.all }]);

    const styles = {
        option: (styles, { isFocused }) => ({
            ...styles,
            fontSize: '15px',
            backgroundColor: isFocused ? 'red' : 'white',
            color: isFocused ? 'white' : 'black',
        }),
        control: (styles) => ({
            ...styles,
            width: '400px',
            boxShadow: 'none',
            borderColor: 'gray',
            '&:hover': {
                borderColor: 'gray',
            },
        }),
    };

    useEffect(() => {
        const getGameOwnerGames = async () => {
            try {
                const res = await api.getGameOwnerGames({
                    params: {
                        season_id: activeSeason,
                    },
                });
                if (res.data.length > 0) {
                    setGames(
                        res.data.map((game) => ({
                            value: game.id,
                            label: `${formatDate(game?.date)} - ${game.away_team_title}`,
                            date: game?.date,
                        })),
                    );
                }
            } catch (e) {
                console.log(e);
            }
        };
        if (activeSeason) {
            getGameOwnerGames();
        }
    }, [activeSeason]);

    return (
        <div className="Select false">
            <>
                <label>{translations.match}</label>
                <Select
                    name="Filter By Name"
                    options={games}
                    styles={styles}
                    defaultValue={
                        defaultValue !== undefined
                            ? games.find((game) => game.value === defaultValue)
                            : games[0]
                    }
                    isSearchable={false}
                    onChangeCallback={(e) => {
                        filterByGameDate(e);
                    }}
                    placeholder={translations.search_by_game}
                />
            </>
        </div>
    );
};

export default React.memo(HomeGamesFilter);
