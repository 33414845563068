import Input from '../../components/Input';
import Checkbox from '../../components/Checkbox';
import translateLabel from '../../helpers/translateLabel';
import InputFile from '../../components/InputFile';
import Select from '../../components/Select';
import DatePicker from '../../components/DatePicker';
import itemTypes from '../../constants/shopItemTypes';
import Textarea from '../../components/Textarea';
import mandatoryFields from '../../configs/shopItem';
import translations from '../../translations/de';
import voucherTypes from '../../constants/voucherTypes';
import ImportVoucherCodes from '../../components/ImportVoucherCodes/ImportVoucherCodes';

export default function getShopFormInput({
    value,
    name,
    register,
    error,
    disabled,
    index,
    resetImageValue,
    resetVoucherValue,
    control,
    handleTypeChange,
    showVoucherTypes,
    isFromEditView = false,
    searchOrdersHandler,
    orders = [],
    handleOrder,
    orderIdHaveValue,
    voucherType,
    setVoucherType,
    orderID,
    setOrderID,
    price_in_cents,
}) {
    if (typeof value === 'boolean') {
        return (
            <Checkbox
                key={index}
                text={translateLabel(name)}
                name={name}
                {...register(name, {
                    required: mandatoryFields.find((field) => (field === name ? true : false)),
                })}
                disabled={disabled}
            />
        );
    } else if (name === 'voucher_type') {
        return showVoucherTypes ? (
            <Select
                key={index}
                text={translateLabel(name)}
                label={translateLabel(name)}
                onChangeCallback={(e) => setVoucherType(e)}
                name={name}
                options={voucherTypes}
                isSearchable={false}
                control={control}
                isClearable={true}
                error={error}
                disabled={disabled}
                isRequired={mandatoryFields.find((field) => (field === name ? true : false))}
            />
        ) : null;
    } else if (name === 'order_id') {
        return (
            voucherType !== 'internal' &&
            voucherType && (
                <Select
                    key={`${index}${voucherType}-${orderID}`}
                    onInputChange={searchOrdersHandler}
                    name={name}
                    defaultValue={value}
                    label={translateLabel(translations.order_id)}
                    options={orders.map((order) => {
                        return {
                            value: order,
                            label: `${order}`,
                        };
                    })}
                    onChangeCallback={(e) => handleOrder(e)}
                    placeholder={translations.search_orders}
                    control={control}
                    error={error}
                    disabled={disabled || orderID !== ''}
                    noOptionsMsg={translations.no_orders_found}
                    isClearable
                    isRequired={mandatoryFields.find((field) => (field === name ? true : false))}
                />
            )
        );
    } else if (name === 'importCodesField') {
        const isFixedTypeValid = voucherType && voucherType !== 'internal';
        return (
            isFixedTypeValid && (
                <ImportVoucherCodes
                    value={value}
                    key={`${index}-${orderID}`}
                    label={translateLabel(name)}
                    name={name}
                    {...register(name, {
                        required: false,
                    })}
                    error={error}
                    control={control}
                    disabled={disabled}
                    orderID={orderID}
                    setOrderID={setOrderID}
                    voucherType={voucherType}
                    resetValue={(val) => resetVoucherValue(name, val)}
                    currentPriceInCents={price_in_cents}
                />
            )
        );
    } else if (name === 'expire_at' || name === 'visible_until') {
        return (
            <DatePicker
                key={index}
                label={translateLabel(name)}
                selectText={translations.select_date}
                name={name}
                withTime={true}
                disabled={disabled}
                control={control}
                error={error}
                isRequired={mandatoryFields.find((field) => (field === name ? true : false))}
            />
        );
    }
    if (name === 'image_url') {
        return (
            <InputFile
                key={`${index}-${value}`}
                label={translateLabel(name)}
                name={name}
                {...register(name, {
                    required: mandatoryFields.find((field) => (field === name ? true : false)),
                })}
                error={error}
                disabled={disabled}
                resetValue={(val) => resetImageValue(name, val)}
                pathToCloudFolder="uploads/shop-items"
            />
        );
    } else if (name === 'type') {
        if (value !== 'lottery_ticket') {
            return isFromEditView ? (
                disabled && (
                    <Select
                        key={index}
                        label={translateLabel(name)}
                        name={name}
                        isSearchable={false}
                        options={itemTypes.filter((item) => item.value !== 'lottery_ticket')}
                        control={control}
                        onChangeCallback={(e) => handleTypeChange(e)}
                        error={error}
                        disabled={true}
                        isRequired={mandatoryFields.find((field) =>
                            field === name ? true : false,
                        )}
                    />
                )
            ) : (
                <Select
                    key={index}
                    label={translateLabel(name)}
                    name={name}
                    isSearchable={false}
                    options={itemTypes.filter((item) => item.value !== 'lottery_ticket')}
                    control={control}
                    onChangeCallback={(e) => handleTypeChange(e)}
                    error={error}
                    disabled={disabled || orderIdHaveValue}
                    isRequired={mandatoryFields.find((field) => (field === name ? true : false))}
                />
            );
        } else {
            return (
                <Input
                    key={index}
                    //{...register(name)}
                    label={translateLabel(name)}
                    disabled={true}
                    value={translations[value]}
                />
            );
        }
    } else if (name === 'used') {
        return (
            disabled && (
                <Input
                    key={index}
                    label={translateLabel(name)}
                    name={name}
                    type={typeof value}
                    {...register(name, {
                        required: mandatoryFields.find((field) => (field === name ? true : false)),
                    })}
                    error={error}
                    disabled={name === 'sold' ? true : disabled}
                />
            )
        );
    } else if (name === 'description' || name === 'redeem_description') {
        return (
            <Textarea
                key={index}
                label={translateLabel(name)}
                name={name}
                type="text"
                {...register(name, {
                    required: mandatoryFields.find((field) => (field === name ? true : false)),
                })}
                error={error}
                disabled={disabled}
            />
        );
    } else if (name === 'price_in_cents') {
        return (
            voucherType === 'eguma' && (
                <Input
                    key={{ index }}
                    label={translateLabel(name)}
                    name={name}
                    type={typeof value}
                    {...register(name, {
                        required: !orderIdHaveValue
                            ? mandatoryFields.find((field) => (field === name ? true : false))
                            : false,
                        valueAsNumber: typeof value === 'number' ? true : false,
                        min: !orderIdHaveValue
                            ? {
                                  value: 1,
                                  message: translations.positive_number,
                              }
                            : 0,
                    })}
                    error={error}
                    disabled={disabled || orderIdHaveValue}
                />
            )
        );
    } else if (typeof value === 'number') {
        return (
            <Input
                key={index}
                label={translateLabel(name)}
                name={name}
                type={typeof value}
                {...register(name, {
                    required: mandatoryFields.find((field) => (field === name ? true : false)),
                    valueAsNumber: typeof value === 'number' ? true : false,
                    min: { value: name === 'sold' ? 0 : 1, message: translations.positive_number },
                })}
                error={error}
                disabled={name === 'sold' ? true : disabled}
            />
        );
    } else if (typeof value === 'string') {
        return (
            <Input
                key={index}
                label={translateLabel(name)}
                name={name}
                type={typeof value}
                {...register(name, {
                    required: mandatoryFields.find((field) => (field === name ? true : false)),
                })}
                error={error}
                disabled={name === 'sold' ? true : disabled}
            />
        );
    }
}
