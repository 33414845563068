import React, { useState, useEffect } from 'react';

import { formatDate } from '../../helpers/formatDate';

import Loader from '../../components/Loader';

import { LineChart, CartesianGrid, XAxis, YAxis, Line, Legend, Tooltip } from 'recharts';

import API from '../../api/analytics';
import translations from '../../translations/de';

const TicketsCreated = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    const customizedXAxisTick = ({ x, y, payload }) => {
        return (
            <g transform={`translate(${x},${y})`} style={{ fontSize: 12 }}>
                <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-45)">
                    {payload.value}
                </text>
            </g>
        );
    };

    useEffect(() => {
        const getData = async () => {
            setLoading(true);
            try {
                const res = await API.getCreatedPasses();
                setData(
                    res.data.map((entry) => {
                        return {
                            date: formatDate(entry.date),
                            count: entry.count,
                        };
                    }),
                );
                setLoading(false);
            } catch (e) {
                setLoading(false);
                console.error(e);
            }
        };
        getData();
    }, []);

    return loading ? (
        <div className="loader-wrapper">
            <Loader theme="dark" size="big" />
        </div>
    ) : (
        <div style={{ marginTop: '100px', display: 'flex', justifyContent: 'center' }}>
            <LineChart
                width={1000}
                height={400}
                data={data}
                margin={{
                    top: 50,
                    right: 30,
                    left: 70,
                    bottom: 40,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" tick={customizedXAxisTick} interval={15} fontSize="1" />
                <YAxis
                    dataKey="count"
                    label={{
                        value: translations.created_tickets_yaxis,
                        position: 'top',
                        offset: 30,
                    }}
                />
                <Legend
                    align="center"
                    verticalAlign="bottom"
                    height={36}
                    content={(props) => {
                        const { payload } = props;
                        return (
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                {payload.map((entry, index) => (
                                    <div
                                        key={`legend-${index}`}
                                        style={{ marginRight: '10px', marginTop: '100px' }}
                                    >
                                        <div
                                            style={{
                                                backgroundColor: entry.color,
                                                width: '10px',
                                                height: '10px',
                                                display: 'inline-block',
                                            }}
                                        />
                                        <span style={{ marginLeft: '5px' }}>
                                            {translations.created_tickets_xaxis}
                                        </span>
                                    </div>
                                ))}
                            </div>
                        );
                    }}
                />
                <Line
                    type="monotone"
                    dataKey="count"
                    stroke="#4472c4"
                    animationDuration={0}
                    dot={false}
                />
                <Tooltip />
            </LineChart>
        </div>
    );
};

export default TicketsCreated;
